<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div class="tableBox">
      <el-table
        :data="tableData"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="80"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="nickName" label="昵称"></el-table-column>
        <el-table-column prop="height" label="头像">
          <template slot-scope="scope">
            <img
              :src="scope.row.avatarUrl"
              alt=""
              v-if="scope.row.avatarUrl"
              style="width: 50px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="height" label="注册时间">
          <template slot-scope="scope">{{
            scope.row.registerDate | formatTime("YMDHMS")
          }}</template>
        </el-table-column>
        <!-- <el-table-column prop label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editData(scope.row)"
              >编辑</el-button
            >
            <el-button @click="deleteData(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
export default {
  components: { searchBar },
  data() {
    return {
      page: {
        toPageNum: 1,
        pageSize: 10,
      },
      params: {},
      total: 0,
      tableData: [],
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "nickName",
          attrs: {
            placeholder: "昵称",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "phone",
          attrs: {
            placeholder: "手机号码",
          },
        },
      ],
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1 + (this.page.toPageNum - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.toPageNum = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.page.toPageNum = 1;
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/admin/admin/wx/user/page",
        { ...this.params, ...this.page },
        "post"
      ).then((res) => {
        this.tableData = res.data.content;
        this.total = res.data.totalElements;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
    },
    editData(item) {
      this.$refs.dialog.getInfo(item.id);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax("api/delUser", { id: item.id }, "post").then((res) => {
          this.$message.success("删除成功");
          this.initPage();
        });
      });
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less"></style>
